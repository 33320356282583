import * as types from './types'

export default {
  authLogin ({ commit }, keycloakAuth) {
    commit(types.SECURITY_AUTH, keycloakAuth)
  },
  authLogout ({ commit }) {
    commit(types.SECURITY_AUTH, types.state)
  },
  impersonate ({ commit }, impersonate) {
    commit(types.IMPERSONATE, impersonate)
  },
  setProperties ({ commit }, properties) {
    commit(types.PROPERTIES, properties)
  },
}
