export const getRm = function (val) {
  if (!val) return 'RM 0.00'
  const rm = Number(val)
  return isNaN(rm) ? 'RM 0.00' : 'RM ' + rm.toFixed(2)
}

export const getNumeralFromRm = function (val) {
  return val ? Number(val.split(' ')[1]) : 0
}

export const getInputs = function (parent) {
  const results = []
  const search = (children, depth = 0) => {
    for (const child of children) {
      if (child.errorBucket !== undefined) {
        results.push(child)
      } else if (child.$el && child.$el.offsetParent !== null) {
        search(child.$children, depth + 1)
      }
    }
    if (depth === 0) return results
  }

  return search(parent.$children)
}

export const getInput = function (parent) {
  const results = []
  const search = (children, depth = 0) => {
    for (const child of children) {
      if (child.errorBucket !== undefined) {
        results.push(child)
      } else {
        search(child.$children, depth + 1)
      }
    }
    if (depth === 0) return results
  }

  return search(parent.$children)
}

export const mnpIcon = 'transform'
export const mnpClass = 'orange darken-2 white--text'
export const newIcon = 'mdi-new-box'
export const newClass = 'light-green darken-2 white--text'
export const searchIcon = 'mdi-incognito'
export const searchClass = 'red darken-2 white-text'
export const spIcon = 'mdi-cards'
export const spClass = 'blue darken-2 white--text'

export const stringGen = function (len) {
  let text = ''
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < len; i++) { text += charset.charAt(Math.floor(Math.random() * charset.length)) }

  return text
}

export const formatPhoneNumber = function (phone) {
  if (!phone) return ''

  phone = String(phone)
  if (phone.startsWith('011')) {
    return phone.substr(0, 4) + ' ' + phone.substr(4, 3) + ' ' + phone.substr(7)
  } else if (phone.startsWith('01')) {
    return phone.substr(0, 3) + ' ' + phone.substr(3, 3) + ' ' + phone.substr(6)
  } else if (phone.startsWith('0')) {
    if (phone.length < 10) { return phone.substr(0, 2) + ' ' + phone.substr(2, 3) + ' ' + phone.substr(5) } else { return phone.substr(0, 2) + ' ' + phone.substr(2, 4) + ' ' + phone.substr(6) }
  } else return phone
}

export const formatAddress = function (addressObject) {
  let address = addressObject.street1.value
  if (addressObject.street2.value) { address += ', ' + addressObject.street2.value }
  if (addressObject.city.value) { address += ', ' + addressObject.city.value }
  if (addressObject.postalCode.value) { address += ', ' + addressObject.postalCode.value }
  if (addressObject.state.value) { address += ' ' + addressObject.state.value }

  return address
}

export const formatAddressFamily = function (addressObject) {
  let address = addressObject.address1.value
  if (addressObject.address2.value) { address += ', ' + addressObject.address2.value }
  if (addressObject.city.value) { address += ', ' + addressObject.city.value }
  if (addressObject.postcode.value) { address += ', ' + addressObject.postcode.value }
  if (addressObject.state.value) { address += ' ' + addressObject.state.value }

  return address
}

export const formatAddressForeigner = function (addressObject) {
  let address = addressObject.fstreet1.value
  if (addressObject.fstreet2.value) { address += ', ' + addressObject.fstreet2.value }
  if (addressObject.fcity.value) { address += ', ' + addressObject.fcity.value }
  if (addressObject.fpostalCode.value) { address += ', ' + addressObject.fpostalCode.value }
  if (addressObject.fstate.value) { address += ' ' + addressObject.fstate.value }

  return address
}

export const getNumeralFromBlackCode = function (code) {
  const str = code.split('')
  return Number(str.filter(char => {
    return char >= 0 && char <= 9
  }).join(''))
}

export const getBasicSimpackPrice = function () {
  return 10.00
}

export const formatNumber = function (number) {
  number = number.toFixed(2) + ''
  const x = number.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const getStateFromMyKad = function (myKad) {
  const code = Number(myKad.substr(6, 2))
  if (isNaN(code)) return null

  switch (code) {
    case 1:
    case 21:
    case 22:
    case 23:
    case 24:
      return 'Johor'
    case 2:
    case 25:
    case 26:
    case 27:
      return 'Kedah'
    case 3:
    case 28:
    case 29:
      return 'Kelantan'
    case 4:
    case 30:
      return 'Melaka'
    case 5:
    case 31:
    case 59:
      return 'Negeri Sembilan'
    case 6:
    case 32:
    case 33:
      return 'Pahang'
    case 7:
    case 34:
    case 35:
      return 'Pulau Pinang'
    case 8:
    case 36:
    case 37:
    case 38:
    case 39:
      return 'Perak'
    case 9:
    case 40:
      return 'Perlis'
    case 10:
    case 41:
    case 42:
    case 43:
    case 44:
      return 'Selangor'
    case 11:
    case 45:
    case 46:
      return 'Terengganu'
    case 12:
    case 47:
    case 48:
    case 49:
      return 'Sabah'
    case 13:
    case 50:
    case 51:
    case 52:
    case 53:
      return 'Sarawak'
    case 14:
    case 54:
    case 55:
    case 56:
    case 57:
      return 'Kuala Lumpur'
    case 15:
    case 58:
      return 'Labuan'
    case 16:
      return 'Putrajaya'
    case 88:
    case 99:
      return 'ArmedForcedID'
    default:
      return null
  }
}
