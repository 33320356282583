import store from '@/store'

export default () => {
  const keycloakAuth = store.getters.SECURITY_AUTH
  let home = location.protocol + '//' + location.hostname
  home += process.env.NODE_ENV === 'production' ? '/' : ':' + location.port + '/'

  keycloakAuth.logout({ redirectUri: home })
  store.dispatch('authLogout')
}
