import init from './init'
import roles from './roles'
import logout from './logout'
import header from './header'
import me from './me'

export default {
  init,
  roles,
  logout,
  header,
  me,
}
