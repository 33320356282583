import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { state } from './types'

export default {
  state,
  actions,
  getters,
  mutations,
}
