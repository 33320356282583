import store from '@/store'

function name () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.name
  } else {
    const keycloakAuth = store.getters.SECURITY_AUTH
    return keycloakAuth.authenticated && keycloakAuth.tokenParsed ? keycloakAuth.tokenParsed.name : null
  }
}

function username () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.username
  } else {
    const keycloakAuth = store.getters.SECURITY_AUTH
    return keycloakAuth.authenticated && keycloakAuth.tokenParsed ? keycloakAuth.tokenParsed.preferred_username : null
  }
}

function givenName () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.givenName
  } else {
    const keycloakAuth = store.getters.SECURITY_AUTH
    return keycloakAuth.authenticated && keycloakAuth.tokenParsed ? keycloakAuth.tokenParsed.given_name : null
  }
}

function familyName () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.familyName
  } else {
    const keycloakAuth = store.getters.SECURITY_AUTH
    return keycloakAuth.authenticated && keycloakAuth.tokenParsed ? keycloakAuth.tokenParsed.family_name : null
  }
}

function email () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.email
  } else {
    const keycloakAuth = store.getters.SECURITY_AUTH
    return keycloakAuth.authenticated && keycloakAuth.tokenParsed ? keycloakAuth.tokenParsed.email : null
  }
}

function getSubscription () {
  const keycloakAuth = store.getters.SECURITY_AUTH
  return keycloakAuth.authenticated && keycloakAuth.tokenParsed ? keycloakAuth.tokenParsed.subscription : null
}

function getDealer () {
  const subscription = getSubscription()
  return subscription ? subscription.dealer : null
}

function claimer () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.claimer
  } else {
    const subscription = getSubscription()
    return subscription ? subscription.claimer : null
  }
}

function mobilePlan () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.mobilePlan
  } else {
    const subscription = getSubscription()
    return subscription ? subscription.mobile_plan : null
  }
}

function oldId () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.oldId
  } else {
    const subscription = getSubscription()
    return subscription ? subscription.old_id : null
  }
}

function msisdn () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.msisdn
  } else {
    const subscription = getSubscription()
    return subscription ? subscription.msisdn : null
  }
}

function dealerId () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.dealerId
  } else {
    const dealer = getDealer()
    return dealer ? dealer.id : null
  }
}

function dealerRank () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.dealerRank
  } else {
    const dealer = getDealer()
    return dealer ? dealer.rank : null
  }
}

function blackDealer () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.blackDealer
  } else {
    const dealer = getDealer()
    return dealer ? dealer.is_black_dealer : false
  }
}

function nuDealer () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.nuDealer
  } else {
    const dealer = getDealer()
    return dealer ? dealer.is_nu_dealer : false
  }
}

function pureNuDealer () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.pureNuDealer
  } else {
    const dealer = getDealer()
    return dealer ? (dealer.is_nu_dealer && dealer.id.startsWith('NU')) : false
  }
}

function getProperties () {
  const impersonate = store.getters.IMPERSONATE
  if (impersonate) {
    return impersonate.properties ? impersonate.properties : []
  } else {
    return store.getters.PROPERTIES ? store.getters.PROPERTIES : []
  }
}

export default {
  name,
  username,
  givenName,
  familyName,
  email,
  claimer,
  dealerId,
  dealerRank,
  blackDealer,
  nuDealer,
  pureNuDealer,
  mobilePlan,
  oldId,
  msisdn,
  getProperties,
}
