import store from '@/store'

export default (roles, resource) => {
  const impersonate = store.getters.IMPERSONATE

  if (impersonate) {
    for (const role of roles) {
      for (const myRole of impersonate.roles) {
        if (myRole === role) return true
      }
    }

    return false
  }
  const keycloakAuth = store.getters.SECURITY_AUTH
  if (keycloakAuth.authenticated) {
    if (keycloakAuth.hasRealmRole('devops')) return true // Always allow devops

    for (const role of roles) {
      if (resource) {
        if (keycloakAuth.hasResourceRole(role, resource)) return true
      } else if (keycloakAuth.hasRealmRole(role)) return true
    }
  }

  return false
}
