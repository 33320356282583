import * as types from './types'

export default {
  [types.SECURITY_AUTH]: state => {
    return state.auth
  },
  [types.IMPERSONATE]: state => {
    return state.impersonate
  },
  [types.PROPERTIES]: state => {
    return state.properties
  },
}
