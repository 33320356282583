import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import './plugins/apexchart'
import App from './App.vue'
import router from './router'
import store from '@/store'
import './registerServiceWorker'
import '@mdi/font/css/materialdesignicons.min.css'
import Rest from './rest'
import Money from 'v-money'
import VueCodeHighlight from 'vue-code-highlight'
import * as VueGoogleMaps from 'vue2-google-maps'
// import Tawk from './tawk'

Vue.config.productionTip = false

Vue.use(Rest)
Vue.use(Money, {
  decimal: '.',
  thousands: ' ',
  suffix: '',
  masked: false,
  precision: 2,
})
Vue.use(VueCodeHighlight)
/*
Vue.use(Tawk, {
  tawkSrc: 'https://embed.tawk.to/5ec181588ee2956d73a1fb5b/default',
})
*/
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCdPAZu-ACyfBJrhen4zzyBKlhO5p04rXQ',
    libraries: 'places',
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
  renderError: (h, err) => {
    // Dev only
    return h('pre', { style: { color: 'red' } }, err.stack)
  },
}).$mount('#app')
