import axios from 'axios'
import security from '@/security'
import store from '@/store'

export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, '$rest', { value: axios })
    const baseURL = process.env.NODE_ENV === 'production'
      ? location.protocol + '//' + location.hostname + '/api/'
      : 'http://172.168.10.13/api/'

    axios.defaults.baseURL = baseURL
    axios.defaults.headers.Accept = 'application/json, application/pdf'
    axios.defaults.timeout = 180000
  },
}

export const createGetUrl = function (url) {
  if (store.state.security.impersonate) {
    const sep = url.includes('?') ? '&' : '?'
    const dbname = security.me.claimer() === 'onex' ? 'onesys' : 'xonesys'

    return url + sep + 'impersonate=' + security.me.dealerId() + '&dbname=' + dbname
  }

  return url
}

export const createGetParams = function (params) {
  const impersonate = store.state.security.impersonate ? { impersonate: store.state.security.impersonate.msisdn } : {}
  const parameters = { params: impersonate }

  params && Object.assign(parameters.params, params)
  return parameters
}

export const createPostParams = function (params) {
  const impersonate = store.state.security.impersonate ? { impersonate: store.state.security.impersonate.msisdn } : {}
  return Object.assign(params, impersonate)
}

export const reset = function (obj) {
  obj.data = obj.error = null
}
