import Keycloak from 'keycloak-js'
import store from '@/store'

const keycloakAuth = new Keycloak('/keycloak.json')

export default (next, roles, sso = false) => {
  const auth = store.getters.SECURITY_AUTH

  if (!auth.authenticated && auth.login) {
    auth.login()
    return
  }

  const param = sso ? { onLoad: 'check-sso' } : { onLoad: 'login-required' }

  keycloakAuth.init(param)
    .success((authenticated) => {
      store.dispatch('authLogin', keycloakAuth)
      if (authenticated) {
        if (roles) {
          let hasRole = false

          if (keycloakAuth.hasRealmRole('devops')) {
            hasRole = true
          } else {
            for (const role of roles) {
              if (keycloakAuth.hasRealmRole(role)) {
                hasRole = true
                break
              }
            }
          }

          if (hasRole) {
            next()
          } else {
            next({ name: 'Unauthorized' })
          }
        } else {
          next()
        }
        setInterval(function () {
          keycloakAuth.updateToken(70)
            .success((refreshed) => {
              refreshed && store.dispatch('authLogin', keycloakAuth)
            })
            .error(() => {
              location.replace(location.protocol + '//' + location.hostname + '/')
            })
        }, 60000)
      } else {
        next()
      }
    })
    .error(() => {
      console.log('Failed to login')
      next()
    })
}
