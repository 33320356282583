export const SECURITY_AUTH = 'SECURITY_AUTH'
export const IMPERSONATE = 'IMPERSONATE'
export const PROPERTIES = 'PROPERTIES'
export const state = {
  auth: {
    authenticated: false,
  },
  impersonate: null,
  properties: [],
}
