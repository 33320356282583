<template>
  <div>
    <section>
      <dashboard v-if="isAuthenticated" />
      <v-parallax
        v-else
        src="img/background.jpg"
        height="535"
      >
        <v-layout
          column
          align-center
          justify-center
        >
          <img
            class="mt-4"
            height="200px"
            src="@/assets/emblem1.png"
            alt="OneSYS"
          >
          <img
            class="ma-3"
            src="@/assets/onesys-text.png"
            alt="OneSYS"
          >
          <h3 class="grey--text text-xs-center">
            One System Because Out There We Are One
          </h3>
          <v-btn
            v-if="!isAuthenticated"
            class="blue lighten-2 mt-5"
            dark
            large
            @click.stop="login()"
          >
            Log In
          </v-btn>
        </v-layout>
        <v-layout
          v-if="showXoxId()"
          column
          align-center
          justify-center
        >
          <v-flex xs12>
            <span>Forgot password?&nbsp;&nbsp;<a href="http://xoxid.xox.com.my/site/reset">Reset your password here!</a></span>
          </v-flex>
          <v-flex xs12>
            <span>New user?&nbsp;&nbsp;<a href="http://xoxid.xox.com.my/user/register">Create your account here!</a></span>
          </v-flex>
          <v-flex xs12>
            <span>Switch user?&nbsp;&nbsp;<a href="https://idcs-2ff89d4b5f2c46df91f6e10eedb4f9e9.identity.oraclecloud.com/oauth2/v1/userlogout">Switch user here!</a></span>
          </v-flex>
          <v-flex xs12>
            <v-btn
              flat
              @click="goToXoxIdHelp()"
            >
              &nbsp;&nbsp;Help On XOX ID
            </v-btn>
          </v-flex>
        </v-layout>
      </v-parallax>
    </section>
    <div v-if="!isAuthenticated">
      <section>
        <v-layout
          column
          wrap
          class="my-5"
          align-center
        >
          <v-flex
            xs12
            sm4
            class="my-3"
          >
            <div class="text-xs-center">
              <h2 class="headline">
                Start Your Own Mobile Business
              </h2>
              <span class="subheading">
                Join us and become our dealer
              </span>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout
                row
                wrap
                align-top
              >
                <v-flex
                  xs12
                  md4
                >
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <v-icon
                        x-large
                        class="blue--text text--lighten-2"
                      >
                        sim_card
                      </v-icon>
                    </v-card-text>
                    <v-card-title
                      primary-title
                      class="layout justify-center"
                    >
                      <div class="headline text-xs-center">
                        Become Our Subscriber
                      </div>
                    </v-card-title>
                    <v-card-text>
                      Subscribe to our mobile network for as low as RM10!
                      Reach out for our dealer to subscribe.
                      Our dealers are all over the places and may just happen to be your neighbor.
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <v-icon
                        x-large
                        class="blue--text text--lighten-2"
                      >
                        assignment_ind
                      </v-icon>
                    </v-card-text>
                    <v-card-title
                      primary-title
                      class="layout justify-center"
                    >
                      <div class="headline">
                        Enrol As A Dealer
                      </div>
                    </v-card-title>
                    <v-card-text>
                      Your dealer can make you a dealer like him.
                      Ask your dealer for the available dealership program and all about the benefits.
                      There are some 40 million potential subscriber-base ready for you to tap into!
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <v-icon
                        x-large
                        class="blue--text text--lighten-2"
                      >
                        business_center
                      </v-icon>
                    </v-card-text>
                    <v-card-title
                      primary-title
                      class="layout justify-center"
                    >
                      <div class="headline text-xs-center">
                        Get Started With OneSYS
                      </div>
                    </v-card-title>
                    <v-card-text>
                      As a dealer, you can run your business from your laptop and mobile phones using
                      the tools we provide here. Watch your performance and incentives online with OneSYS.
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
      <section>
        <v-parallax
          src="img/background.jpg"
          height="380"
        >
          <v-layout
            column
            align-center
            justify-center
          >
            <div class="headline white--text mb-3 text-xs-center">
              Becoming our subscriber has never been easier
            </div>
            <em>Switch to our mobile network here &amp; now!</em>
            <v-btn
              class="blue lighten-2 mt-5"
              dark
              large
              href="https://onexox.my/selfcare/login"
            >
              Get Started
            </v-btn>
          </v-layout>
        </v-parallax>
      </section>
      <section v-if="showXoxId()">
        <v-layout
          row
          wrap
          justify-center
        >
          <v-flex xs8>
            <div
              ref="xoxid"
              class="headline white--text my-3 text-xs-center"
            >
              Help on XOX ID
            </div>
          </v-flex>
          <v-flex
            xs12
            md8
          >
            <v-expansion-panel
              focusable
              inset
            >
              <v-expansion-panel-content
                v-for="(item,i) in loginFaq"
                :key="i"
              >
                <template #header>
                  <div>{{ i + 1 }}. {{ item.question }}</div>
                </template>
                <v-card>
                  <v-card-text v-html="item.answer" />
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-flex>
        </v-layout>
      </section>
    </div>
    <section>
      <v-container grid-list-xl>
        <v-layout
          row
          wrap
          justify-center
          class="my-5"
        >
          <v-flex
            xs12
            sm4
          >
            <v-card class="elevation-0 transparent">
              <v-card-title
                primary-title
                class="layout justify-center"
              >
                <div class="headline">
                  Company Info
                </div>
              </v-card-title>
              <v-card-text>
                XOX MOBILE, is a Mobile Virtual Network Operator (MVNO) officially offering
                its services to the public in the year 2005. Providing a variety of mobile
                services ranging from affordable rates to different consumer needs and wants
                as well as flexible payment and top-up options to cater to today’s versatile consumer.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex
            xs12
            sm4
            offset-sm1
          >
            <v-card class="elevation-0 transparent">
              <v-card-title
                primary-title
                class="layout justify-center"
              >
                <div class="headline">
                  Connect With Us
                </div>
              </v-card-title>
              <v-card-title class="layout justify-center">
                <v-btn
                  v-for="site in socialMedia"
                  :key="site.icon"
                  :href="site.href"
                  class="mx-3"
                  icon
                >
                  <v-icon size="40px">
                    {{ site.icon }}
                  </v-icon>
                </v-btn>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  components: {
    Dashboard: () => import(/* webpackChunkName: "dashboard" */ '@/components/Dashboard'),
  },
  data: () => ({
    loginFaq: [
      {
        question: 'What is XOX ID?',
        answer: 'Your XOX ID is the account you use to access all XOX digital services like ONESYS, ONEXAPP, XOX Selfcare, MyXOX, and many more applications that will be introduce in the future. You will only need XOX ID to access all XOX digital and online services.',
      },
      {
        question: 'How it works?',
        answer: 'SIGN UP FOR XOX ID WITH 3 SIMPLE STEPS!<br><br><ol type="A"><li>CREATE XOX ID ACCOUNT<br>Fill up the required information:<br>First Name, Last Name, Email Address, Password, Confirm Password, Contact Number, Agree to PDPA</li><li>ACTIVATE XOX ID ACCOUNT<br>Upon successful registration, you will receive XOX ID Account Activation Link. Click the link to activate your XOX ID account</li><li>LINK XOX ID WITH MSISDN<br>After account activation, Log in to XOX ID. Key in your XOX number (DREG NUM) to tie XOX ID with your profile.</li></ol>',
      },
      {
        question: 'How to Create XOX ID Account?',
        answer: 'In Onesys homepage you will see a link “New user? Create your account here!” Click that link to be directed to XOX ID account creation page. Fill up all the required information. Upon successful account registration, you will receive an email to activate your account. Please click the link that is given in the email to activate your account.',
      },
      {
        question: 'How to Login in?',
        answer: 'In Onesys homepage you will see Log in with XOX ID button, kindly click that button to be directed to login page. Fill in registered email id and password and click submit to log in.<br><br>NOTE: You will be only able to log in if activate your account.',
      },
      {
        question: 'How to link my XOX ID with MSISDN (DREG NUM)?',
        answer: 'Upon successful login, you will be asked to key in DREG number. Once you key in the DREG Num, OTP will be sent to the number. Enter the OTP and done. Your XOX ID is successfully linked with you MSISDN.',
      },
      {
        question: 'What can I do if my account activation link expire even before I activate my account?',
        answer: 'Click the expired link and you will be notified that the activation link expired. You will see a button to request for new activation link.',
      },
      {
        question: 'What happens if I forget my password?',
        answer: 'You can request to reset your password by clicking the “Forgot password? Reset your password here!” option which you can find in Onesys Homepage or XOX ID login page. Key in your email address to receive password reset link.',
      },
      {
        question: 'What can I do if my password rest link expire even before I reset my password?',
        answer: 'Click the expired link and you will be notified that the link expired. You can proceed to reset your password by clicking the button. Key in your email address to receive new password reset link.',
      },
      {
        question: 'Can I request to reset password before I activate my XOX ID account?',
        answer: 'No, you are not allowed to reset password before account activation.',
      },
      {
        question: 'I key in the wrong password for multiple times and now my account locked. What can I do to unlock my account?',
        answer: 'Please kindly proceed to reset your password.',
      },
      {
        question: 'How to logout?',
        answer: 'There are two types of logout. When you logout from your dashboard, you are only logging out from onesys and not from XOX ID. What it means is that if you have your MSISDN also connected to selfcare, you will be able to use selfcare without have to log in again. This is because your XOX ID account is not logged out. To log out from XOX ID from Onesys, you have to first logout from onesys dashboard then you have to click “Switch user? Switch user here!” option. By doing this you will be totally logged out from XOX ID.',
      },
      {
        question: 'I have logout from my account but when other dealer trying to log in using my pc, they are hitting error message stating “The credentials that you entered don’t match the existing user session”.',
        answer: 'When you come across this scenario, please kindly click “Switch user? Switch user here!” option. You will be able to log in successfully without any issue.',
      },
      {
        question: 'What is Switch User?',
        answer: 'Assuming two Dealers want to share one PC to login into Onesys. After Dealer A done with his session, Dealer B have to use “Switch user? Switch user here!” option to log into his account.',
      },
      {
        question: 'Can I create another XOX ID using MSISDN which already have XOX ID linked with?',
        answer: 'No, you are not allowed to do that. One ID is for one MSISDN.',
      },
      {
        question: 'If I\'m having some issues or difficulties with XOX ID, with whom can I liaise with or engage with?',
        answer: 'Kindly drop an email to dmt@onexox.my',
      },
    ],
    title: null,
    socialMedia: [
      {
        icon: 'mdi-facebook',
        href: 'https://www.facebook.com/groups/FAQonexox',
      },
      {
        icon: 'mdi-twitter',
        href: 'https://twitter.com/xox_malaysia',
      },
      {
        icon: 'mdi-instagram',
        href: 'https://www.instagram.com/xoxmalaysia',
      },
    ],
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.state.security.auth.authenticated
    },
  },
  /*
  created: function () {
    if (document.referrer.indexOf('epic') > -1 && !this.isAuthenticated) {
      if (this.$route.query.logout === 'true') {
        this.$router.push('/')
      } else {
        this.$router.push('/login')
      }
    }
  },
  */
  methods: {
    showXoxId: function () {
      return !this.isAuthenticated && false
    },
    goToXoxIdHelp: function () {
      this.$vuetify.goTo(this.$refs.xoxid, {
        duration: 1000,
        offset: 0,
        easing: 'easeInOutCubic',
      })
    },
    login: function () {
      this.$router.push('/login')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
